html,body{
    height: 100%;
}

html {
  overscroll-behavior-y: contain;
}

body {
  margin: 0;
  font-size: 16px;
  font-family: "Glacial Indifference", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  background-color: white !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
